import Swiper, {Pagination, Navigation, Autoplay, Thumbs} from "swiper";
Swiper.use([Thumbs]);

const mainProductCardSlider = document.querySelector('.main-slider__top');
const thumbsProductCardSlider = document.querySelector('.slider-thumbs__bottom');

const swiperThumbsCardSlider = new Swiper(thumbsProductCardSlider, {
    slideClass: 'main-slider__slide',
    wrapperClass: 'slider-thumbs__wrapper',
    slidesPerView: 'auto',
    spaceBetween: 15,
    freeMode: true,
    direction: 'horizontal',
    breakpoints: {
        1440: {
            slidesPerView: 4,
            direction: 'vertical',
            allowTouchMove: false,
        },
}
});

const swiperMainCardSlider = new Swiper(mainProductCardSlider, {
    slideClass: 'main-slider__slide',
    wrapperClass: 'main-slider__wrapper',
    slidesPerView: '1',
    freeMode: true,
    direction: 'horizontal',
    centeredSlides: true,
    thumbs: {
        swiper: swiperThumbsCardSlider,
      },
      breakpoints: {
        1440: {
            slidesPerView: 4,
            direction: 'vertical',
        },   
}});



