function setCookie(name, value, options = {}) { 
 
      options = { 
        path: '/', 
        ...options 
      }; 
     
      if (options.expires instanceof Date) { 
        options.expires = options.expires.toUTCString(); 
      } 
     
      let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value); 
     
      for (let optionKey in options) { 
        updatedCookie += "; " + optionKey; 
        let optionValue = options[optionKey]; 
        if (optionValue !== true) { 
          updatedCookie += "=" + optionValue; 
        } 
      } 
     
      document.cookie = updatedCookie; 
}      
     
function getCookie(name) { 
      let matches = document.cookie.match(new RegExp( 
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)" 
      )); 
      return matches ? decodeURIComponent(matches[1]) : undefined; 
}

function deleteCookie(name) {
  setCookie(name, "", {
    'max-age': -1
  })
}

// Вывод количества товаров в сравнении
const outputNumberCompare = function() {
  const counter = document.querySelector('.header__sub-nav-item--compare .header__counter');
  let dataIdCopmare = getCookie("comparison");

  if (!dataIdCopmare) { 
    counter.classList.add('empty');
    return; 
  }
  dataIdCopmare = dataIdCopmare.split(',');

  if (dataIdCopmare.includes('null')) {
    let index = dataIdCopmare.indexOf("null");
    if (index >= 0) {
      dataIdCopmare.splice(index, 1);
    }
  }

  if (dataIdCopmare.length) {
    counter.textContent = dataIdCopmare.length;
    counter.classList.remove('empty');
  } else {
    counter.classList.add('empty');
  }
}

// Добавление data-id compare кнопки при клике
const addCookieCopmare = function() {
  const button = document.querySelectorAll('.product-item__compare-button');

  button.forEach((button) => button.addEventListener('click', function() {
    let cook = button.getAttribute("data-id");  
    let oldCook = getCookie('comparison');  
    let resultCook;
    if (oldCook == undefined) {
      resultCook = cook;  
    } else {
      if(oldCook.includes(cook)) {
        resultCook = oldCook.replace(`${cook}`, '');
        resultCook = resultCook.replace(`,,`, ',');
        if(resultCook.startsWith(',')) resultCook = resultCook.substring(1,resultCook.length);
        if(resultCook.endsWith(',')) resultCook = resultCook.substring(0,resultCook.length-1);
      } else {
        resultCook = oldCook + ',' + cook;
      }
    } if (resultCook=='') {
      deleteCookie('comparison')
    } else {
      setCookie('comparison', resultCook, {'max-age': 3600*24*365});
    }

    button.classList.toggle('product-item__button--active');
    outputNumberCompare();
  }))
}

// Реализация стилей активной Copmare кнопки
const handlerClickCopmare = function() {
  let dataIdCopmare = getCookie("comparison");
  if(dataIdCopmare != undefined) 
  {
    dataIdCopmare = dataIdCopmare.split(',');
    let copmareButton = document.querySelectorAll('.product-item__compare-button');
    const arrId = Array.from(copmareButton, function(a) { return a.getAttribute("data-id") })
    const filteredArray = arrId.filter(value =>  dataIdCopmare.includes(value));
    let buttonCompareActive;
    for (let i = 0; i < filteredArray.length; i++) {
      buttonCompareActive = document.querySelector('.product-item__compare-button' + `[data-id="${filteredArray[i]}"]`);
      buttonCompareActive.classList.add('product-item__button--active');
    }
  }
}

// Вывод количества товаров в избранном
const outputNumberFavorites = function() {
  const counter = document.querySelector('.header__sub-nav-item--heart .header__counter');
  let dataIdFavourites = getCookie("favourites");

  if (dataIdFavourites == null) {
    counter.classList.add('empty');
  } else {
    dataIdFavourites = dataIdFavourites.split(',');
    counter.textContent = dataIdFavourites.length;
    counter.classList.remove('empty');
  }
}
 
// Добавление data-id fav кнопки при клике
const addCookieFav = function() {
  const button = document.querySelectorAll('.product-item__fav-button');
  
  button.forEach((button) => button.addEventListener('click', function() {
    let cook = button.getAttribute("data-id");  
    let oldCook = getCookie('favourites');  
    let resultCook;

    if (oldCook == undefined) {
      resultCook = cook;  
    } else {
      if (oldCook.includes(cook)) {
        resultCook = oldCook.replace(`${cook}`, '');
        resultCook = resultCook.replace(`,,`, ',');
        if(resultCook.startsWith(',')) resultCook = resultCook.substring(1,resultCook.length);
        if(resultCook.endsWith(',')) resultCook = resultCook.substring(0,resultCook.length-1);
      } else {
        resultCook = oldCook + ',' + cook;
      }
    }
    if (resultCook=='') {
      deleteCookie('favourites')
    } else {
      setCookie('favourites', resultCook, {'max-age': 3600*24*365});
    }
    button.classList.toggle('product-item__button--active');

    outputNumberFavorites();
  }))
}

// Реализация стилей активной fav кнопки
const handlerClickFavourites = function() {
  let dataIdFavourites = getCookie("favourites");

  if (dataIdFavourites != undefined) {
    dataIdFavourites = dataIdFavourites.split(',');
    let favButton = document.querySelectorAll('.product-item__fav-button');
    const arrId = Array.from(favButton, function(a) { return a.getAttribute("data-id") })
    const filteredArray = arrId.filter(value =>  dataIdFavourites.includes(value));
    let buttonActive;

    for (let i = 0; i < filteredArray.length; i++) {
      buttonActive = document.querySelector('.product-item__fav-button' + `[data-id="${filteredArray[i]}"]`);
      buttonActive.classList.add('product-item__button--active');
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {

  handlerClickFavourites();

  handlerClickCopmare();

  addCookieCopmare();

  addCookieFav();

  outputNumberFavorites();

  outputNumberCompare();
})