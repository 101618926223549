"use strict";
!(function () {
    const e = {
        options: { touch: "move", infinite: !0, autostart: !0, preloadImages: !0, preventScroll: !1 },
        i: 0,
        touchStartX: null,
        touchChangeUnify: (e) => (e.changedTouches ? e.changedTouches[0] : e),
        touchStartHandler(t) {
            e.touchStartX = e.touchChangeUnify(t).clientX;
        },
        touchEndHandler(t) {
            if (e.touchStartX || 0 === e.touchStartX) {
                let r = e.touchChangeUnify(t).clientX - e.touchStartX,
                    i = Math.sign(r);
                if (((e.touchStartX = null), 0 === i)) return;
                const a = this,
                    o = a.querySelector(".hover-slider-images-toggler"),
                    n = a.querySelector("img.hover-slider-init"),
                    s = a.querySelector(".hover-slider-indicator"),
                    l = s.querySelector(".active");
                let d = null;
                if (
                    (i > 0
                        ? ((d = l.previousElementSibling), !d && e.getOption(n, "infinite") && (d = s.querySelector("div.hover-slider-indicator-dot:last-of-type")))
                        : ((d = l.nextElementSibling), !d && e.getOption(n, "infinite") && (d = s.querySelector("div.hover-slider-indicator-dot:first-of-type"))),
                    d)
                ) {
                    l.classList.remove("active"), d.classList.add("active");
                    const t = d.dataset.hoverSliderI,
                        r = o.querySelector(`div[data-hover-slider-i="${t}"]`);
                    e.changeImgByTogglePane(r, n);
                }
            }
        },
        changeImgByTogglePane(t, r) {
            const i = t.dataset.hoverSliderI,
                a = t.dataset.hoverSliderImage,
                o = e.findLoadedImageById(i);
            if (!1 !== o) r.replaceWith(o);
            else {
                const t = e.createVirtualImage(r, a);
                e.loadedImages.push({ el: t, id: i, src: a }), r.replaceWith(t);
            }
        },
        togglePaneEnterListener(t, r = null) {
            const i = r || this,
                a = i.parentElement.parentElement.querySelector("img.hover-slider-init");
            e.changeImgByTogglePane(i, a);
            const o = i.dataset.hoverSliderI,
                n = i.parentElement.parentElement.querySelector(".hover-slider-indicator");
            n.querySelector(".active").classList.remove("active"), n.querySelector(`.hover-slider-indicator-dot[data-hover-slider-i="${o}"]`).classList.add("active");
        },
        createElement(t, r = {}) {
            let i = null;
            switch (t) {
                case "imagesToggler":
                    (i = document.createElement("div")),
                        i.classList.add("hover-slider-images-toggler"),
                        (i.style.position = "absolute"),
                        (i.style.top = "0"),
                        (i.style.right = "0"),
                        (i.style.bottom = "0"),
                        (i.style.left = "0"),
                        (i.style.display = "flex");
                    break;
                case "togglePane":
                    (i = document.createElement("div")),
                        i.classList.add("hover-slider-toggle-pane"),
                        (i.style.flexGrow = "1"),
                        (i.dataset.hoverSliderImage = r.slideSrc),
                        (i.dataset.hoverSliderI = e.i),
                        i.addEventListener("mouseenter", e.togglePaneEnterListener, !1);
                    break;
                case "sliderIndicator":
                    (i = document.createElement("div")), i.classList.add("hover-slider-indicator");
                    break;
                case "indicatorDot":
                    (i = document.createElement("div")), (i.dataset.hoverSliderI = e.i), i.classList.add("hover-slider-indicator-dot"), r.active && i.classList.add("active");
            }
            return i;
        },
        getOption(t = null, r) {
            let i = { ...e.options };
            return t && t.dataset.options && (i = Object.assign(i, JSON.parse(t.dataset.options))), i[r];
        },
        loadedImages: [],
        findLoadedImageById(t) {
            const r = e.loadedImages.find(function (e) {
                if (parseInt(e.id) === parseInt(t)) return !0;
            });
            return void 0 !== r && r.el;
        },
        createVirtualImage(e, t) {
            const r = document.createElement("img");
            return r.setAttribute("src", t), (r.classList = e.classList), (r.dataset.options = e.dataset.options), r.classList.add("hover-slider-init"), r;
        },
        prepareMarkupForImage(t) {
            const r = t.parentElement;
            let i = [];
            i.push(t.getAttribute("src"));
            const a = t.dataset.hoverSlides;
            try {
                i = i.concat(JSON.parse(a));
            } catch (e) {
                i = i.concat(a.split(","));
            }
            i = i.map(function (e) {
                return e.trim();
            });
            const o = e.createElement("imagesToggler"),
                n = e.createElement("sliderIndicator");
            i.forEach(function (r, i) {
                e.i++,
                    o.append(e.createElement("togglePane", { slideSrc: r })),
                    n.append(e.createElement("indicatorDot", { active: 0 === i })),
                    !0 === e.getOption(t, "preloadImages") && e.loadedImages.push({ el: e.createVirtualImage(t, r), id: e.i, src: r });
            }),
                "relative" !== getComputedStyle(r).position && (r.style.position = "relative"),
                "move" === e.getOption(t, "touch") &&
                    r.addEventListener(
                        "touchmove",
                        (r) => {
                            const i = r.touches[0],
                                a = document.elementFromPoint(i.clientX, i.clientY);
                            a && a.classList.contains("hover-slider-toggle-pane") && e.togglePaneEnterListener(r, a), e.getOption(t, "preventScroll") && r.preventDefault();
                        },
                        !1
                    ),
                "end" === e.getOption(t, "touch") &&
                    (r.addEventListener("touchstart", e.touchStartHandler, !1),
                    r.addEventListener("touchend", e.touchEndHandler, !1),
                    e.getOption(t, "preventScroll") &&
                        r.addEventListener(
                            "touchmove",
                            (e) => {
                                e.preventDefault();
                            },
                            !1
                        )),
                r.prepend(n),
                r.prepend(o),
                t.classList.add("hover-slider-init");
        },
        prepareMarkup(t = null) {
            if (t) return void e.prepareMarkupForImage(t);
            document.querySelectorAll("img[data-hover-slides]:not(.hover-slider-init)").forEach(function (t) {
                e.prepareMarkupForImage(t);
            });
        },
        init(t = {}) {
            return window.hoverSliderOptions && (e.options = Object.assign(e.options, window.hoverSliderOptions)), (e.options = Object.assign(e.options, t)), e.getOption(null, "autostart") && this.prepareMarkup(), this;
        },
    };
    window.hoverSlider = e.init();
})();
