export const setupBlocks = ([selector, BlockClass]) => {
  document
    .querySelectorAll(selector)
    .forEach((element) => new BlockClass(element));
};

export const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
    callback.apply(null, args);
    }, wait);
  };
}
