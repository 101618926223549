import {activateFocusTrap,deactivateFocusTrap,} from "./../utils/focus-trap.js";
import { MEDIA } from "./../utils/media.js";

export default class Menu {
  constructor(element) {
    this._menu = element;
    this._openButtons = document.querySelectorAll(".js-open-menu");
    this._closeButton = document.querySelector(".js-close-menu");
    this._categoriesWrapper = this._menu.querySelector(".menu__cats");
    this._topSearch = document.querySelector('.top-search');
    this._searchBtn = document.querySelector('.search-btn');

    this._activeButton = null;
    window._isMenuOpen = false;

    this._init();
  }

  _init() {
    this._togglingVisibility();
    this._showDetails();
  }

  _togglingVisibility() {
    this.openMenu = this._openMenu.bind(this);
    this._openButtons.forEach((button) =>
      button.addEventListener("click", () => {
        window._isMenuOpen ? this._closeMenu() : this.openMenu();
      })
    );
  }

  _openMenu() {
    window.scrollTo(0, 0);
    this._activeButton = document.querySelector(".js-open-menu");
    this._activeButton.classList.toggle("active");
    document.documentElement.classList.toggle("menu-showed");

    this.closeMenu = this._closeMenu.bind(this);

    this._closeButton.addEventListener("click", this.closeMenu);
    activateFocusTrap(this._menu);
    document.body.classList.add('lock');
    window._isMenuOpen = !window._isMenuOpen;
    if (this._topSearch.classList.contains('active')) {
      this._topSearch.classList.remove('active');
      this._searchBtn.classList.remove('active');
    }
  }

  _closeMenu() {
    this._openButtons.forEach(button => {
      if (button.classList.contains('active')) {
        button.classList.remove("active");
      }
    });    
    document.documentElement.classList.remove("menu-showed");
    this._closeButton.removeEventListener("click", this._closeMenu);
    deactivateFocusTrap(this._menu);
    document.body.classList.remove('lock');
    window._isMenuOpen = !window._isMenuOpen;
  }

  _showDetails() {
    if (MEDIA.lg.matches) return;
    const menuItems = document.querySelectorAll("li.menu__item");
    const subcatsContains = [...menuItems].filter((item) => item.querySelector("li.menu__item") !== null);
    const subcatsUncontains = [...menuItems].filter((item) => item.querySelector("li.menu__item") === null);
    const backLinks = document.querySelectorAll(".menu__back");

    const hideDetails = (evt) => {
      evt.preventDefault();

      if (evt.target.classList.contains("menu__back")) {
        evt.target.closest(".menu__item").classList.remove("active");
      }
    };

    const showDetails = (evt) => {
      const parentListItem = evt.target.closest('li');

      if (parentListItem.classList.contains("menu__item") && parentListItem.querySelector("ul .menu__item") && !evt.target.classList.contains('menu__back')) {
        evt.preventDefault();
        evt.currentTarget.classList.add("active");
      }
    };

    subcatsUncontains.forEach((el) => {
      if (el.querySelector("a svg")) {
        el.querySelector("a svg").remove();
      }
    });

    subcatsContains.forEach((el) => {
      el.addEventListener("click", showDetails);
    });

    backLinks.forEach((el) => {
      el.addEventListener("click", hideDetails);
    });
  }
}
