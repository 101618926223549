import {deactivateFocusTrap,} from "./../utils/focus-trap.js";
import { MEDIA } from "./../utils/media.js";

(() => {
  const searchBtn = document.querySelector(".search-btn");

  if (!searchBtn) return;

  const topSearch = document.querySelector(".top-search");
  const searchInput = document.querySelector(".top-search__input");
  const headerSearchCover = document.querySelector(".header__search-cover");
  const openMenuBtns = document.querySelectorAll(".js-open-menu");

  searchBtn.addEventListener("click", (e) => {
    window.scrollTo(0, 0);
    
    if (e.target.classList.contains('active')) {
      document.body.classList.remove("lock");
      e.target.classList.remove("active");
    } else {
      document.body.classList.add("lock");
      e.target.classList.add("active");
    }

    topSearch.classList.toggle("active");

    openMenuBtns.forEach((btn) => {
      if (btn.classList.contains("active")) {
        btn.classList.remove("active");
        document.documentElement.classList.remove("menu-showed");
        deactivateFocusTrap(document.querySelector('.menu'));
        window._isMenuOpen = !window._isMenuOpen;
      }
    });
  });

  const initSearch = () => {
    if (MEDIA.lg.matches) {
      searchInput.addEventListener("focus", showSearch);
      headerSearchCover.addEventListener("click", hideSearch);
    } else {
      searchInput.removeEventListener("focus", showSearch);
      headerSearchCover.removeEventListener("click", hideSearch);
    }
  };

  window.addEventListener("resize", initSearch);

  initSearch();

  function showSearch() {
    document.querySelector(".header__main").classList.add("onfocus");
  }

  function hideSearch(e) {
    if (e.target) {
      document.querySelector(".header__main").classList.remove("onfocus");
    }
  }
})();
