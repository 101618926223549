import {
  MEDIA
} from './media.js';

export const settings = {
  cats: {
    slideClass: 'cats__slide',
    wrapperClass: 'slider__inner',
    slidesPerView: 1,
    loop: true,
    navigation: {
      prevEl: '.cats__controls .swiper-button-prev',
      nextEl: '.cats__controls .swiper-button-next',
    },
    on: {
      resize: function () {
        MEDIA.sm.matches ? this.disable() : this.enable();
      },
      init: function () {
        if (MEDIA.sm.matches) this.disable();
      }
    }
  },

  hits: {
    slideClass: 'product-item',
    wrapperClass: 'slider__inner',
    slidesPerView: 'auto',
    spaceBetween: 40,
    navigation: {
      prevEl: '.hits__controls .swiper-button-prev',
      nextEl: '.hits__controls .swiper-button-next',
    },
    on: {
      resize: function () {
        MEDIA.sm.matches ? this.disable() : this.enable();
      },
      init: function () {
        if (MEDIA.sm.matches) this.disable();
      }
    }
  },

  partners: {
    slideClass: 'js-infinite-line__item',
    wrapperClass: 'logo-block__wrap',
    direction: 'horizontal',
    slidesPerView: 'auto',
    spaceBetween: 30,
    speed: 1500,
    centeredSlides: true,
    loop: true,
    autoplay: {
      delay: 1500,
    },

    breakpoints: {
      1280: {
        spaceBetween: 40
      }
    }
  },

  special: {
    slideClass: 'special__item',
    wrapperClass: 'special__slider-wrapper',
    direction: 'horizontal',
    spaceBetween: 5,
    slidesPerView: 'auto',
    speed: 1000,
    centeredSlides: true,
    loop: true,
    autoplay: {
      delay: 1600,
    },
    pagination: {
      el: '.special__pagination',
      type: 'bullets',
      bulletClass: 'slider__bullet',
      bulletActiveClass: 'slider__bullet--active',
      bulletElement: 'div',
      clickable: 'true'
    },
  },
}