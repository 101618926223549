const tabsSpecificationsButtonElements = document.querySelectorAll('.specifications-tabs__btn');
const tabsSpecificationsContentElements = document.querySelectorAll('.specifications-tabs__content');

tabsSpecificationsButtonElements.forEach(function(item) {
    item.addEventListener('click', function() {
        let currentItem = item;
        let tabId = currentItem.getAttribute('data-tab');
        let currentTab = document.querySelector(tabId);


        tabsSpecificationsButtonElements.forEach(function(item) {
            item.classList.remove('specifications-tabs__btn--active');
        });

        tabsSpecificationsContentElements.forEach(function(item) {
            item.classList.remove('specifications-tabs__content--active');
        });
        currentItem.classList.add('specifications-tabs__btn--active');
        currentTab.classList.add('specifications-tabs__content--active');
    })
});

const tabsAdditionalInformationButtonElements = document.querySelectorAll('.additional-information__tab');
const tabsAdditionalInformationContentElements = document.querySelectorAll('.additional-information__slide');

tabsAdditionalInformationButtonElements.forEach(function(item) {
    item.addEventListener('click', function() {
        let currentItem = item;
        let tabId = currentItem.getAttribute('data-tab');
        let currentTab = document.querySelector(tabId);


        tabsAdditionalInformationButtonElements.forEach(function(item) {
            item.classList.remove('additional-information__tab--active');
        });

        tabsAdditionalInformationContentElements.forEach(function(item) {
            item.classList.remove('additional-information__slide--active');
        });
        currentItem.classList.add('additional-information__tab--active');
        currentTab.classList.add('additional-information__slide--active');
    })
});