import '../libs/select.min.js';

(() => {
    let selects = document.querySelectorAll('select');

    if (!selects.length) return

    selects.forEach(select => {
        customSelect(select, {
            containerClass: "select",
            openerClass: "select__opener",
            panelClass: "select__panel",
            optionClass: "select__option",
            optgroupClass: "select__optgroup",
            isSelectedClass: "is-selected",
            hasFocusClass: "has-focus",
            isDisabledClass: "is-disabled",
            isOpenClass: "is-open",
        });
    })
})();