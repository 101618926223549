import Swiper, {Pagination, Navigation, Autoplay} from "swiper";
import {settings} from '../utils/slidersSettings.js';

Swiper.use([Navigation, Pagination, Autoplay]);

(() => {
    const sliders = document.querySelectorAll('.slider');
    

    if (!sliders.length) return;

    sliders.forEach((slider) => {
        const slides = slider.querySelectorAll('.swiper-slide');
        if (!slides.length) return;
        const sliderType = slider.dataset.type;

        new Swiper(slider, settings[sliderType]);
    })
})();


 document.addEventListener('DOMContentLoaded', function() {

    const limiterCardSlide = function() {

        const slides = document.querySelectorAll('.hover-slider-images-toggler');
        
        for(let i = 0; i < slides.length; i++) {
            let doteWrapper = slides[i].nextSibling;
            let dotes = doteWrapper.children;
            let images = slides[i].children;

            if(images.length > 7) {

                for (let i = images.length - 1; i > 6; --i) {
                    dotes[i].remove();
                    images[i].remove();
                }
            }
        } 
    }

    limiterCardSlide();
})


