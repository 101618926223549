(() => {
    const productTabs = document.querySelectorAll('.products__cat');
    let activeTab = document.querySelector('.products__cat.active');

    if (!productTabs.length) return;

    productTabs.forEach((tab) => {
        tab.addEventListener('click', (evt) => {
            evt.preventDefault();
            if (activeTab) {
                activeTab.classList.remove('active');
                activeTab.classList.add('inactive');
                activeTab = null;
            }

            tab.classList.remove('inactive');
            tab.classList.add('active');

            activeTab = tab;
        })
    })
})();