(() => {
    const catalogList = document.querySelector('.products__list');
    const viewModeBlock = document.querySelector('.view-mode');
    let currentMode = 'grid';

    if (!viewModeBlock) return;

    viewModeBlock.addEventListener('click', e => {
        const target = e.target.closest('button');

        if (!target) return;

        const currentButton = viewModeBlock.querySelector('button.active');
        currentButton.classList.remove('active');

        catalogList.style.opacity = 0;

        target.classList.add('active');
        currentMode = target.dataset.mode
        viewModeBlock.classList.toggle('active');

        setTimeout(() => {
            catalogList.classList.toggle('list');
        }, 250);

        setTimeout(() => {
            catalogList.style.opacity = 1;
        }, 400);

        window.dispatchEvent(new CustomEvent('view-changed', {
          detail: { mode: currentMode }
        }));
    })
})();
