const focusableElements = `
  a[href],
  area[href],
  button:not([disabled]):not([aria-hidden]),
  input:not([disabled]):not([aria-hidden]):not([type="hidden"]),
  select:not([disabled]):not([aria-hidden]),
  textarea:not([disabled]):not([aria-hidden]),
  embed,
  iframe,
  object,
  [contenteditable],
  [tabindex]:not([tabindex^="-"])
`;
let lastFocusableInBlock = null;
let firstFocusableInBlock = null;
let lastFocusableInBlockBeforeActive = [];


const keydownListener = (e) => {
  let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

  if (!isTabPressed) {
    return;
  }

  if (e.shiftKey) {
    if (document.activeElement === firstFocusableInBlock) {
      lastFocusableInBlock.focus();
      e.preventDefault();
    }
  } else {
    if (document.activeElement === lastFocusableInBlock) {
      firstFocusableInBlock.focus();
      e.preventDefault();
    }
  }
};

export const activateFocusTrap = (block) => {
  block.setAttribute('tabindex', 0);
  lastFocusableInBlockBeforeActive.push(document.activeElement);

  firstFocusableInBlock = block.querySelectorAll(focusableElements)[0];
  const focusableItems = block.querySelectorAll(focusableElements);
  lastFocusableInBlock = focusableItems[focusableItems.length - 1];

  document.addEventListener('keydown', keydownListener);
}

export const deactivateFocusTrap = (block) => {
  document.removeEventListener('keydown', keydownListener);
  block.removeAttribute('tabindex');
  const lastActive = lastFocusableInBlockBeforeActive[lastFocusableInBlockBeforeActive.length - 1];

  if (lastActive) {
    lastActive.focus();
    lastFocusableInBlockBeforeActive.pop();
  }
}
