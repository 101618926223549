import Menu from "./modules/Menu.js";
import Filter from "./modules/Filter.js";
import { setupBlocks } from "./utils/common.js";

[
  // [".menu", Menu],
  [".filters", Filter]
].forEach(setupBlocks);

let menu = document.querySelector('.menu');
new Menu(menu);

//libs

import './libs/hoverSlider.min.js';

// modules
import './modules/sliders.js';
import './modules/productCats.js';
import './modules/search.js';
import './modules/forms.js';
import './modules/viewMode.js';
import './modules/selects.js';
import './modules/parallax.js';
import './modules/cards.js';
import './modules/product-card-sliders.js';
import './modules/product-card-tabs.js';
import './modules/cookie.js';
import './modules/comparison.js';

hoverSlider.init({
  hideOneDot: true
});
