import { MEDIA } from "../utils/media.js";

  export default class Filter {
    constructor(element) {
      this._filter = element;
      this._openFilterButton = document.querySelector('.filters-btn');
      this._closeFilterButton = this._filter.querySelector('.filters-catalog__button-close');
      this.body = document.body;
      this._init()
    }

    _init() {
      this._setupFilter();
      this._checkDevice();
    }

    _setupFilter() {
      this.checkDevice = this._checkDevice.bind(this);
      this.openFilter = this._openFilter.bind(this);
      window.addEventListener('resize', this.checkDevice);
      this._openFilterButton.addEventListener('click', this.openFilter)
    }

    _openFilter() {
      this.body.classList.add('lock');
      this._filter.classList.add('open');
      this.closeFilter = this._closeFilter.bind(this)
      this._closeFilterButton.addEventListener('click', this.closeFilter)
    }

    _closeFilter() {
      this.body.classList.remove('lock');
      this._filter.classList.remove('open');
      this._closeFilterButton.removeEventListener('click', this.closeFilter)
    }

    _checkDevice() {
      !MEDIA.sm.matches
          ? this._filter.classList.remove('mobile')
          : this._filter.classList.add('mobile');
    }
  }


// Сортировка в листинге товаров

$(function(){
  $(function () {
      $('.goods-sort-options').children('a').each(function () {
          if ($(this).hasClass('active')){
              $(this).detach().prependTo('.goods-sort-options');
          }
      });
  })

  $('.sort-open').click(function(){
      $('.goods-sort-options').children('a').toggleClass('open');
      $('.goods-sort-options').toggleClass('open');
  })
})
