
(() => {

const target = document.querySelector('.parallax');

if (!target) return;
const parallaxLayers = target.querySelectorAll('.parallax__layer');

const scrollHandler = () => {
  parallaxLayers.forEach(el => {

    const y = (el.getBoundingClientRect().top * el.dataset.speed).toFixed(2);
    el.setAttribute('style', `transform: translateY(${y}px);`)
  })
}

const initParallax = () => {
  document.addEventListener('scroll', scrollHandler);
}

const resetParallax = () => {
  document.removeEventListener('scroll', scrollHandler);
}

const observer = new IntersectionObserver((entries) => {
  // eslint-disable-next-line no-unused-expressions
  entries[0].isIntersecting
    ? initParallax()
    : resetParallax();
  }, {});

observer.observe(target);
})();
