(() => {
  const cards = document.querySelectorAll(".products__list .product-item");

  if (!cards.length) return;

  let height = 0;

  const setHeight = () => {
    if (window.matchMedia("(min-width: 1280px)").matches) {
      cards.forEach((el) => {
        if (el.getBoundingClientRect().height > height) {
          height = el.getBoundingClientRect().height;
        }
      });

      cards.forEach((el) => {
        el.style.height = `${height}px`;
      });
    } else {
      cards.forEach((el) => {
        el.style.height = null;
      });
    }
  };

  const resetHeight = () => {
    cards.forEach((el) => {
        el.style.height = null;
    })
  };

  setHeight();

  window.addEventListener("resize", () => {
    setHeight();
  }, true);

  window.addEventListener('view-changed', (evt) => {
    const mode = evt.detail.mode;

    console.log('mode:' + mode);

    // eslint-disable-next-line no-unused-expressions
    mode === 'grid'
      ? setHeight()
      : resetHeight();
  })
})();
