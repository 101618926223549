(() => {
    const inputs = document.querySelectorAll('input:not([type="checkbox"]), textarea')

    const checkState = function () {
        let parent = this.closest('.input-wrapper');
        if (!parent) return;

        this.value === ''
            ? parent.classList.remove('active-field')
            : parent.classList.add('active-field')
    }

    inputs.forEach((el) => {
        el.addEventListener('change', checkState);
    });
})();


